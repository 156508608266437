.banner {
    // height: 14vh;
    height: 85px;
    position: absolute;
    width: 100vw;
    top: 0;

    overflow-x: clip;
    // background-image: url('./images/banner.svg');
    // background-repeat: no-repeat;
    // background-position-y: -20px;
    h1 {
        color: white;
        margin: 0;
        padding: 8px 0 0 18px;
    }
    .logout-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 30px;
        width: 30px;
        background-image: url('./images/logout-icon.svg');
        background-repeat: no-repeat;
    }

    #banner-svg {
        position: absolute;
        top: -40px;
        z-index: -1;
        path {
            // fill: red !important;
        }
    }
}

.banner-placeholder {
    height: 62px;
}
