.transaction {

    span {
        text-transform: capitalize;
    }

    .summary {
        div {
            display: flex;
            width: 100%;

            .balance {
            margin: 0 5px;
            padding: 5px 8px;
            border-radius: 15px;

            color: white;
            font-weight: 300;

            &.positive {
                background-color: #46AB42;
            }

            &.negative {
                background-color: #CD3838;
            }

            &.volume {
                margin-left: 5px;
                background-color: #4292AB;
            }
        }
        .name {
            margin: 0 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            flex-grow: 1;

            font-weight: 600;
        }

        .hide {
            display: none;
        }

        .time {
            // position: absolute;
            // right: 5px;

            margin: 0 5px;

            text-align: right;
            padding-top: 5px;
            padding-bottom: 5px;

        }
        }

        
    }

    .details {
        padding: 10px !important;

        display: flex;

        .pay {
            flex-grow: 1;
        }

        p {
            align-self: center;
            margin: 0;
        }
    }
}

.MuiAccordion-root {
    div {
        padding: 0 !important;
    }
}