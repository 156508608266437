.filler {
    flex-grow: 1;
}

#settings-page {
    flex-grow: 1;
    overflow: scroll;
}

// #allgemein {
    // flex-grow: 1;
    // display: flex;
    // flex-direction: column;

.settings-element {
    width: 100vw;
    height: 50px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    background-color: #F3F3F3;
    i {
        height: 21px;
        width: 21px;
        // position: absolute;
        // transform: translate(-50%, -50%);
        z-index: 1;
        background-repeat: no-repeat;
    }
    i:nth-child(1), svg:nth-child(1) {
        margin-left: 25px;
        color: black;
    }
    p:nth-child(2) {
        flex-grow: 1;
        margin-top: 4px;
    }
    i:last-child, svg:last-child {
        // width: 10vw;
        margin-right: 5px;
        color: black;
    }
    .switch {
    }
}

#change-password {
    .icon {
        background-image: url('../images/padlock.svg');
    }
    .arrow {
        background-image: url('../images/settings_arrow.svg');
    }
}

#change-ranking {
    .icon {
        background-image: url('../images/ranking.svg');
    }
}

#change-spesen {
    .icon {
        background-image: url('../images/dollar-symbol.svg');
    }
}

#change-party {
    .icon {
        background-image: url('../images/location.svg');
    }
    .select-party {
        min-width: 100px;
        margin-right: 12px;
    }
}
// }

.settings-element {
    width: 100vw;
    height: 50px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    background-color: #F3F3F3;

    p {
        margin-top: 4px;
    }

}
#informationen {

    .settings-element {
        p {
            
            margin-top: 4px;

            &:nth-child(2) {
                flex-grow: 1;
            }

            &:nth-child(3) {
                margin-right:12px;
            }
        }
    }
}


#info {
    margin: 10px 0;
    font-size: 0.6rem;
    text-align: center;
}
.capitalize {
    text-transform: capitalize;
}