.login {
    background: transparent linear-gradient(180deg, #FFE99E 0%, #EEB800 100%) 0% 0% no-repeat padding-box;
    height: 100vh;
    width: 100vw;

    display: grid;
    justify-content: center;
    align-content: center;

    .footer{
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        bottom: 10px;
        height: 75px;
        width: 100%;

        & > * {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        
        .logo {
            background-image: url('../images/logo.svg');
            background-repeat: no-repeat;
            width: 23px;
            height: 45px;
        }

        h3 {
            bottom: 0;
            color: white;
            opacity: 0.8;
            text-shadow: b;
            text-shadow: 0px 3px 6px rgba(0,0,0,0.54);
            margin: 0;
        }
    }
    
}

form {

    max-width: 300px;

    width: 100%;
    height: 155px;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;


    & label *, & input {
        // width: 100%;
        // height: 42px;

        padding: 11px;
        border: none;
        border-radius: 24px;


        box-shadow: 0px 3px 6px #00000029;
    }

    label input {
        font: normal normal 300 15px/20px Roboto;
        width: 240px;
    }

    > input {
        padding: 4.5px;

        font: normal normal bold 25px/33px Roboto;
        color: #FFFFFF;

        background-color: rgba(255, 255, 255, 0.3);
    }
}