$height: 50px;

nav {
    height: $height;
    width: 100vw;
    background: #E9E9E9 0% 0% no-repeat padding-box;
    border-radius: 12px 12px 0 0;
    opacity: 1;

    ul {
        height: $height;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        list-style-type: none;

        .menu-item {
            div {
                margin: auto;
                i {
                    height: 21px;
                    width: 21px;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    background-repeat: no-repeat;
                }
                #dashboard {
                    background-image: url('./images/dashboard.svg');
                }
                #ranking {
                    background-image: url('./images/ranking.svg');
                }
                #statistics {
                    background-image: url('./images/statistics.svg');
                }
                #log {
                    background-image: url('./images/log.svg');
                }
                #settings {
                    background-image: url('./images/settings.svg');
                }
            }
            a.menu-selected::after {
                height: 25px;
                width: 25px;
                position: absolute;
                border-radius: 12px;
                transform: translate(-8px, -8px);
                content: "";
                background-color: #EEB800;
            }
        }
    }
}