.overview {
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .numbers {
        flex-grow: 1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        div {
            text-align: center;
            h2 {
                margin: 0;

                &.first-rank {
                    color: #E2B007;
                }
                &.second-rank {
                    color: #c0c0c0;
                }
                &.third-rank {
                    color: #cd7f32;
                }
            }
            p {
                margin: -4px 0 0 0;
                font-size: 0.7em;
            }
        }

        .guthaben {
            .positive {
                color: #46AB42;
            }
            .negative {
                color: #CD3838;
            }
        }
    }
}

.last-activities {
    height: 23vh;
    // min-height: 162px;
    // height: 22vh;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #F3F3F3;
    .title {
        padding-top: 10px;
        font-weight: 700;
    }
    .activities-container {
        height: 100%;
        display: flex;
        align-items: center;
        overflow-x: scroll;
        flex-grow: 1;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .activity {
            width: 84px;
            // min-height: 110px;
            height: 85%;
            position: relative;
            margin-left: 20px;
            flex-shrink: 0;
            background-color: #ffffff;
            -webkit-box-shadow: 0px 3px 15px 6px rgba(0, 0, 0, 0.28);
            box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            transition: all 1s linear;

            &.new:nth-child(1) {
                animation: new-activity-animation ease-in-out 1s;
            }

            &.ghost {
                opacity: 0.4;
            }

            &.payment {
                background-color: #6BFF7B !important;
            }

            &:nth-child(1) {
                background-color: #FFCD24;
            }
            &:nth-child(2) {
                background-color: #FFDD6B;
            }
            &:nth-child(3) {
                background-color: #FFE89B;
            }
            &:nth-child(4) {
                background-color: #FFEFBB;
            }
            &:nth-child(5) {
                background-color: #fff7db;
            }
            &:nth-child(6) {
                background-color: #fffbec;
            }
            p {
                font-size: 0.68em;
                margin: 0 0 0 7px;
            }
            .name {
                font-weight: 500;
                padding-top: 10px;
                padding-bottom: 7px;
            }
            .drinker-name {
                margin-top: -7px;
                font-size: 0.5rem;
                text-transform: capitalize;
            }
            .hide {
                display: none;
            }
            .amount {
                padding-bottom: 5px;
            }
            .volume {
                padding-bottom: 5px;
            }
            .time {
                position: absolute;
                bottom: 5px;
                left: 0;
                font-size: 0.45rem;
            }

            .payer-name {
                position: absolute;
                bottom: 5px;
                left: 0;
                font-size: 0.45rem;
            }

            img {
                position: absolute;
                top: 10px;
                right: 7px;
                height: 69%;
            }
        }
    }
}

.capitalize {
    text-transform: capitalize;
}

.consumption {
    min-height: 44vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    p {
        font-size: 0.9em;
        margin-bottom: 10px;
    }
    #drinks {
        // height: 80%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        .drink {
            width: 50px;
            height: 90%;
            // min-height: 173px;
            padding: 3%;
            position: relative;
            &:nth-child(odd) {
                background: #F3F3F3 0% 0% no-repeat padding-box;
            }
            .MuiSlider-root {
                
                $slider-height: 92px;
                $slider-width: 38px;
                $slider-radius: 19px;
                margin: auto;
                height: 50%;
                width: $slider-width;
                position: relative;
                // background: #EEB80066 0% 0% no-repeat padding-box;
                border-radius: $slider-radius;
                opacity: 1;
            }
            .slider {
                $slider-height: 92px;
                $slider-width: 38px;
                $slider-radius: 19px;
                margin: auto;
                height: 50%;
                width: $slider-width;
                position: relative;
                background: #EEB80066 0% 0% no-repeat padding-box;
                border-radius: $slider-radius;
                opacity: 1;
                .circle {
                    height: $slider-width;
                    width: $slider-width;
                    position: absolute;
                    bottom: 0;
                    background: #EEB800 0% 0% no-repeat padding-box;
                    border-radius: $slider-radius;
                    .arrow {
                        width: 9px;
                        height: 14px;
                        margin: auto;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%) rotate(-90deg);
                        background-image: url('../images/drink_arrow.svg');
                        background-repeat: no-repeat;
                    }
                }
            }
            input[type=range] {
                height: 50%;
                margin: auto;
                -webkit-appearance: slider-vertical;
                writing-mode: bt-lr;
            }
            .price {
                position: absolute;
                bottom: 0%;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                text-align: center;
                font-size: 0.7rem;
            }
            // input[type=range]::-webkit-slider-thumb {
            //     -webkit-appearance: none;
            // }
            // input[type=range]:focus {
            //     outline: none;
            //     /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
            // }
            // input[type=range]::-ms-track {
            //     width: 100%;
            //     cursor: pointer;
            //     /* Hides the slider so custom styles can be added */
            //     background: transparent;
            //     border-color: transparent;
            //     color: transparent;
            // }
            // /* Special styling for WebKit/Blink */
            // input[type=range]::-webkit-slider-thumb {
            //     -webkit-appearance: none;
            //     border: 0;
            //     height: 38px;
            //     width: 38px;
            //     border-radius: 19px;
            //     background: #eeb800;
            //     margin-top: -14px;
            //     /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
            // }
            // /* All the same stuff for Firefox */
            // input[type=range]::-moz-range-thumb {
            //     border: 0;
            //     height: 38px;
            //     width: 38px;
            //     border-radius: 19px;
            //     background: #eeb800;
            // }
            // /* All the same stuff for IE */
            // input[type=range]::-ms-thumb {
            //     border: 0;
            //     height: 38px;
            //     width: 38px;
            //     border-radius: 19px;
            //     background: #eeb800;
            // }
            // input[type=range]::-webkit-slider-runnable-track {
            //     width: 38px;
            //     height: 92px;
            //     background: #f8e399;
            //     border-radius: 19px;
            //     border: 0;
            // }
            // input[type=range]:focus::-webkit-slider-runnable-track {
            //     background: #f8e399;
            // }
            // input[type=range]::-moz-range-track {
            //     width: 38px;
            //     height: 92px;
            //     background: #f8e399;
            //     border-radius: 19px;
            //     border: 0;
            // }
            // input[type=range]::-ms-track {
            //     width: 38px;
            //     height: 92px;
            //     background: transparent;
            //     border-color: transparent;
            //     border-width: 16px 0;
            //     color: transparent;
            // }
            // input[type=range]::-ms-fill-lower {
            //     background: #f8e399;
            //     border-radius: 19px;
            // }
            // input[type=range]:focus::-ms-fill-lower {
            //     background: #f8e399;
            // }
            // input[type=range]::-ms-fill-upper {
            //     background: #f8e399;
            //     border-radius: 19px;
            // }
            // input[type=range]:focus::-ms-fill-upper {
            //     background: #f8e399;
            // }
            img {
                height: 35%;
                margin: auto;
                position: absolute;
                left: 50%;
                bottom: 18px;
                transform: translateX(-50%);
            }
        }
    }
    .settings {
        padding: 5% 42px;
        display: flex;
        justify-content: space-between;
    }
}

// .confirmation {
//     position: absolute;
//     width: 100vw;
//     height: 100vh;
// }
.blur {
    animation: blur 2s ease;
    -webkit-animation: blur 2s ease;
    -moz-animation: blur 2s ease;
}

@keyframes blur {
    0% {
        -webkit-filter: blur(0px);
        -moz-filter: blur(0px);
        -o-filter: blur(0px);
        -ms-filter: blur(0px);
    }
    50% {
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
    }
    100% {
        -webkit-filter: blur(0px);
        -moz-filter: blur(0px);
        -o-filter: blur(0px);
        -ms-filter: blur(0px);
    }
}

// @keyframes push {
//     0% {
//         transform: translateX(-50px);
//     }
//     100% {
//         transform: translateX(0px);}
// }

.new-activity {
    animation: new-activity-animation ease-in-out 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: new-activity-animation ease-in-out 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: new-activity-animation ease-in-out 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: new-activity-animation ease-in-out 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: new-activity-animation ease-in-out 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}

@keyframes new-activity-animation {
    0% {
        opacity: 0;
        // transform: translate(-76px, 0px);
        background-color: #24ff66;
        margin-left: -84px;
    }
    50% {
        opacity: 1;
        // transform: translate(0px, NaNpx);
        margin-left: 20px;
        background-color: #24ff66;
    }
    100% {
        background-color: #FFCD24;
    }
}

@-moz-keyframes new-activity-animation {
    0% {
        opacity: 0;
        margin-left: -84px;
        background-color: #24ff66;
    }
    50% {
        opacity: 1;
        margin-left: 20px;
        background-color: #24ff66;
    }
    100% {
        background-color: #FFCD24;
    }
}

@-webkit-keyframes new-activity-animation {
    0% {
        opacity: 0;
        margin-left: -84px;
        background-color: #24ff66;
    }
    50% {
        opacity: 1;
        margin-left: 20px;
        background-color: #24ff66;
    }
    100% {
        background-color: #FFCD24;
    }
}

@-o-keyframes new-activity-animation {
    0% {
        opacity: 0;
        margin-left: -84px;
        background-color: #24ff66;
    }
    50% {
        opacity: 1;
        margin-left: 20px;
        background-color: #24ff66;
    }
    100% {
        background-color: #FFCD24;
    }
}

@-ms-keyframes new-activity-animation {
    0% {
        opacity: 0;
        margin-left: -84px;
        background-color: #24ff66;
    }
    50% {
        opacity: 1;
        margin-left: 20px;
        background-color: #24ff66;
    }
    100% {
        background-color: #FFCD24;
    }
}