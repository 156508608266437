// load fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
body {
    font-family: 'Roboto', sans-serif;
    margin: 0
}

p {
    font-weight: 200;
    font-size: 0.8rem;
    margin-left: 15px;
    margin-bottom: 0;
}

h1 {
    font-weight: 400;
}

// .content {
//     height: 100vh;
// }

// .banner {
//     // height: 14vh;
//     height: 85px;
//     position: absolute;
//     width: 100vw;
//     top: 0;
//     background-image: url('../images/banner.svg');
//     background-repeat: no-repeat;
//     background-position-y: -20px;
//     h1 {
//         color: white;
//         margin: 0;
//         padding: 20px 0 0 18px;
//     }
//     .logout-icon {
//         position: absolute;
//         top: 5px;
//         right: 5px;
//         height: 30px;
//         width: 30px;
//         background-image: url('../images/logout-icon.svg');
//         background-repeat: no-repeat;
//     }
// }


// .content {
//     display: flex;
//     flex-direction: column;
// }

// .banner-placeholder {
//     height: 85px;
// }


// #menu {
//     height: 50px;
//     width: 100vw;
//     background: #E9E9E9 0% 0% no-repeat padding-box;
//     border-radius: 12px 12px 0 0;
//     opacity: 1;
//     display: flex;
//     justify-content: space-evenly;
//     align-items: center;
//     .menu-item {
//         div {
//             margin: auto;
//             i {
//                 height: 21px;
//                 width: 21px;
//                 position: absolute;
//                 transform: translate(-50%, -50%);
//                 z-index: 1;
//                 background-repeat: no-repeat;
//             }
//             #dashboard {
//                 background-image: url('../images/dashboard.svg');
//             }
//             #ranking {
//                 background-image: url('../images/ranking.svg');
//             }
//             #statistics {
//                 background-image: url('../images/statistics.svg');
//             }
//             #log {
//                 background-image: url('../images/log.svg');
//             }
//             #settings {
//                 background-image: url('../images/settings.svg');
//             }
//         }
//         &.menu-selected div::after {
//             height: 25px;
//             width: 25px;
//             position: absolute;
//             border-radius: 12px;
//             transform: translate(-8px, -8px);
//             content: "";
//             background-color: #EEB800;
//         }
//     }
// }