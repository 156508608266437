.MuiSnackbar-root {
    position: relative !important;
    margin-top: 6px;
}

.info-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;

    .MuiSnackbar-root {
        left: 0;
        right: 0;
    }
}
  
  