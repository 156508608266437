#ranking-container {
    height: 0px;
    flex-grow: 1;
    overflow: scroll;

    #elements {

        .element {
            display: flex;

            padding: 5px 10px;

            .rank {
                // margin-right: 10px;
                // flex-grow: 1;
                width: 35px;
            }
            .name {
                flex-grow: 1;
                &::first-letter {
                    text-transform: capitalize;
                }
            }
            // .volume {
            // }

            &:nth-child(odd) {
                background-color: #E9E9E9;
            }
        }
    }

}