#debt {
    text-align: center;
    margin: 20px;

    h2 {
        margin: 0;
    }

    p {
        margin: -4px 0 0 0;
        font-size: 0.7em;
    }
}