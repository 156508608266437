.search-user {
    position: relative;
    padding: 2px 4px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: 32px;

    // overflow: visible;
    // align-items: center;
    // width: 400;

    // &:focus-within #results {
    //     display: block;
    // }
    // #results:hover {
    //     display: block;
    // }
    // #results:active {
    //     display: block;
    // }
}

#search-user-input {
    grid-column-start: 1;
    grid-row-start: 1;
}

#search-input-icon {
    grid-column-start: 1;
    grid-row-start: 2;
}

#results {
    // display: none;

    position: absolute;
    top: 34px;
    left: 4px;
    right: 3px;
    z-index: 99;
    padding: 0;

    background-color: white;
    border: 1px solid black;

    li {
        padding: 4px 16px;

        &.selected {
            background-color: #2a7d94;
            color: white;
        }
    }
}

#quicksearch > div:nth-child(1) > div:nth-child(1) {
    margin-bottom: 12px;
   }
   